/* font import start css */
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@font-face {
  font-family: "Times New Roman";
  src: url("assets/font/TimesNewRomanPSMT.eot");
  src: local("assets/font/Times New Roman"), local("TimesNewRomanPSMT"),
    url("assets/font/TimesNewRomanPSMT.eot?#iefix") format("embedded-opentype"),
    url("assets/font/TimesNewRomanPSMT.woff") format("woff"),
    url("assets/font/TimesNewRomanPSMT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times New Roman";
  src: url("assets/font/TimesNewRomanPS-BoldMT.eot");
  src: local("assets/font/Times New Roman Bold"),
    local("TimesNewRomanPS-BoldMT"),
    url("assets/font/TimesNewRomanPS-BoldMT.eot?#iefix")
      format("embedded-opentype"),
    url("assets/font/TimesNewRomanPS-BoldMT.woff") format("woff"),
    url("assets/font/TimesNewRomanPS-BoldMT.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* font import css over */

/* same css start */
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* same css over */

/* Login signup forgot reset password and reset link related css start */
/* .login-page-start .image-portion img {
  height: calc(100vh - 40px);
  object-fit: cover;
} */

.bg-color-change {
  padding: 25px !important;
}

.logo-with-register-login-section {
  max-width: 466px;
  margin: 0 auto;
}

.same-login-resiter-forgot-pass h1 {
  font-weight: 600;
  font-size: 24px;
  color: #292d32;
}

.same-login-resiter-forgot-pass p {
  font-weight: 600;
  font-size: 14px;
  color: #6a6c70;
}

.same-login-resiter-forgot-pass .all-input-cls {
  border: 1px solid #cccccc !important;
  border-radius: 6px !important;
  height: 48px;
  color: #6a6c70 !important;
  padding-left: 40px;
}

.same-login-resiter-forgot-pass .all-input-cls:focus {
  box-shadow: none;
}

.same-login-resiter-forgot-pass .all-input-cls::placeholder {
  color: #6a6c70 !important;
}

.same-login-resiter-forgot-pass .same-all-btn {
  background: #28aff8;
  box-shadow: 0px 4px 10px rgba(40, 175, 248, 0.32);
  border-radius: 6px;
  border: transparent;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  height: 48px;
}

.same-login-resiter-forgot-pass .anchor-link {
  color: #292d32;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}

.all-same-ics {
  position: absolute;
  top: 0;
  margin: 10px 0 0 13px;
}

.reset-portion-link .reset-link h1 {
  font-weight: 600;
  font-size: 24px;
  color: #292d32;
}

.reset-portion-link .paragraph-portion-link p {
  font-weight: 600;
  font-size: 14px;
  color: #6a6c70;
  line-height: 28px;
}

.reset-portion-link .paragraph-portion-link a {
  color: #28aff8;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-decoration: none;
}

/* Login signup forgot reset password and reset link related css over */

/* Sidebar component related css start */
.sidebar-portion-start {
  background: #ffffff;
  padding: 18px 8px 18px 0px;
}

.sidebar-portion-start ul li a .link-text {
  color: #6a6c70;
  font-weight: 600;
  font-size: 14px;
}

.sidebar-portion-start ul .gapping-cls-listing {
  margin: 16px 0 16px 0;
}

ul.list-unstyled li.gapping-cls-listing .active {
  background: #28aff8;
  box-shadow: 0px 4px 10px rgba(40, 175, 248, 0.32);
  border-radius: 8px;
  width: 100%;
}

.active span.link-text {
  color: #fff !important;
}

.active path {
  fill: #fff;
}

ul.list-unstyled li.gapping-cls-listing a {
  padding: 13px 13px 13px 13px;
}

.pagination-all-cls ul li.page-item.active a {
  background: #28aff8;
  border: 1px solid #28aff8;
  color: #fff !important;
}

.pagination-all-cls ul li a.page-link {
  color: #28aff8;
}

/* Sidebar component related css over */

/* Table related css start */

.bg-color-change {
  background-color: #f0f6f9;
  padding: 30px;
  min-height: 100vh;
}

.table-cls-portion {
  border-radius: 10px;
  /* max-height: 513px; */
}

.table-cls-portion .my-3 {
  overflow-y: auto;
  overflow-x: auto !important;
}

.table-cls-portion::-webkit-scrollbar {
  width: 13px;
  scroll-behavior: smooth;
}

.table-cls-portion::-webkit-scrollbar-track {
  background: #f0f6f9;
  border-radius: 80px;
}

.table-cls-portion::-webkit-scrollbar-thumb {
  background: #aec9d7;
  border-radius: 80px;
}

.custom-table-invoice {
  background: #f0f6f9;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: inherit !important;
}

.custom-border-cls tr {
  border-bottom: 1px solid #eef5f9 !important;
}

.dashboard-portion-start .invoice-data-all .invoice-heading h1 {
  color: #292d32;
  font-weight: 600;
  font-size: 25px;
}

.dashboard-portion-start .invoice-data-all .invoice-heading p {
  color: #a8b8c0;
  font-weight: 600;
  font-size: 18px;
}

.table-cls-portion h3 {
  font-weight: 600;
  font-size: 22px;
  color: #292d32;
}

.table-cls-portion .custom-table-invoice th {
  font-weight: 600;
  color: #292d32;
  white-space: nowrap;
}

.custom-border-cls tr td {
  color: #292d32 !important;
  font-weight: 400;
}

.dashboard-portion-start .invoice-data-all .table-cls-portion .color-cls {
  color: #ffaa2b !important;
}

.notification-label {
  background-color: #cceaff;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1px 10px 1px 10px;
  margin: -10px -8px 0 0;
}

.notification-label span {
  color: #292d32;
  font-weight: 600;
  font-size: 15px;
}

.table-responsive::-webkit-scrollbar {
  width: 18px;
  scroll-behavior: smooth;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f0f6f9;
  border-radius: 80px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #aec9d7;
  border-radius: 80px;
}

.dropdown.filter-btn button {
  background: #fff;
  border: transparent;
  color: #292d32;
  font-weight: 600;
  border-radius: 6px;
  padding: 8px;
}

.dropdown.filter-btn button:after {
  content: inherit !important;
}

.dropdown.filter-btn button:focus-visible {
  outline: none;
}

.table-responsive .table .custom-border-cls td {
  vertical-align: middle;
}

.table-responsive .table thead tr {
  vertical-align: middle;
}

.custom-modal-click-open {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.submit-btn-cls {
  background: #28aff8;
  border-radius: 6px;
  font-weight: 600;
  border: transparent;
  padding: 13px 23px 13px 23px;
}

.custom-modal-click-open .modal-header h4 {
  color: #292d32;
  font-weight: 600;
  font-size: 25px;
}

.custom-modal-click-open .modal-header {
  border-bottom: none;
}

.custom-modal-click-open .modal-header .btn-close {
  border: 1px solid #292d32;
  border-radius: 100%;
  opacity: 1;
}

.custom-modal-click-open .form-label {
  color: #292d32;
  font-weight: 600;
}

.custom-modal-click-open input,
.form-select {
  border: 1px solid #d3e0e7 !important;
  border-radius: 6px;
  outline: transparent !important;
  background-color: transparent;
  color: #6a6c70;
  height: 45px;
}

.custom-modal-click-open input::placeholder {
  color: #6a6c70;
}

.active-list-item {
  background-color: lightblue !important;
}

.custom-modal-click-open option {
  color: #6a6c70;
}

.submit-payment-record {
  background: #28aff8;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  border: transparent;
  height: 40px;
  margin-top: 34px;
  margin-left: 15px;
}

.submit-payment-record:hover {
  background: #28aff8;
}

.submit-payment-record:active {
  background-color: #28aff8 !important;
}

.calendar-ics {
  position: absolute;
  right: 0;
  top: 0;
  margin: 40px 19px 0px 0px;
  cursor: pointer;
}

.invoice-tabbing-view-cls ul li {
  background: #ffffff;
  border-radius: 6px;
  margin: 0 15px 0 0px;
}

.invoice-tabbing-view-cls ul li button {
  color: #292d32;
  font-weight: 600;
}

.invoice-tabbing-view-cls ul li button:hover {
  color: #28aff8;
}

.invoice-tabbing-view-cls ul li .active {
  background: transparent !important;
  border: 1px solid #28aff8;
  color: #28aff8 !important;
  border-radius: 6px;
}

.plus-ics-cls {
  background-color: #ffffff;
  border: 1px solid #e2e4e9;
  border-radius: 6px;
  margin-top: 32px;
  width: fit-content;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-ics-cls svg path {
  fill: #063a5f !important;
}

.change-color-cls path {
  fill: #7e8da0 !important;
}

.add-btns button.btn.btn-primary {
  background: #28aff8;
  border: transparent;
}

.my-user-profile-table-cls table tr {
  border-bottom: none !important;
}

.view-data-portion-add p {
  text-align: center;
  font-weight: 600;
}

.view-data-confirm button {
  border: transparent;
  background: #28aff8;
}

.link-generation-cls-click .modal-content .modal-body .paragraph-heading p {
  line-height: 37px;
}

.link-generation-cls-click .modal-content {
  padding: 20px;
}

.main-table-start-dashboard {
  background: #ffffff;
  border-radius: 10px;
  padding: 22px;
  height: fit-content;
}

.main-table-start-dashboard span {
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 600;
}

.add-user-heading h6 {
  color: #292d32;
  font-weight: 600;
  font-size: 18px;
}

.set-position-dashboard {
  position: absolute;
  left: 0;
}

.custom-btn button {
  background: #fff;
  border: transparent;
  border-radius: 6px;
  color: #292d32;
  font-weight: 600;
  padding: 8px 8px 8px 13px;
}

.custom-btn button:hover {
  background: transparent;
}

.required-asterisk {
  color: red;
}

.input-btn-cls input {
  background: #ffffff;
  border-radius: 6px;
  border: transparent;
  padding: 8px 8px 8px 13px;
  color: #292d32;
  font-weight: 600;
}

.input-btn-cls input::placeholder {
  color: #292d32;
  font-weight: 600;
}

.dashboard-area-start ul {
  background: #ffffff;
  border: 1px solid #dee9ee;
  border-radius: 8px;
  width: fit-content;
  padding: 8px 8px 8px 8px;
  margin: 0 auto;
}

.dashboard-area-start ul li.nav-item .nav-link.active {
  background: #179bae;
  border-radius: 6px;
  color: #28aff8;
  padding: 9px 32px 9px 32px;
  font-weight: 600;
}

.dashboard-area-start ul li.nav-item .nav-link {
  color: #292d32;
  padding: 9px 32px 9px 32px;
  font-weight: 600;
}

.chart-heading h6 {
  color: #292d32;
  font-weight: 600;
  font-size: 16px;
}

.attachment-file {
  position: relative;
}

.attachment-file input.file-input {
  opacity: 0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.all-selection-same-cls {
  position: relative;
}

.all-selection-same-cls input {
  border: none !important;
}

.all-selection-same-cls .ng-dropdown-panel-items.scroll-host {
  background: #fff;
  margin: 13px 11px 0 -11px;
  border: 1px solid #6a6c70;
  padding: 4px 0px 0px 13px;
}

.all-selection-same-cls .ng-clear-wrapper {
  position: absolute !important;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px 0 0 0;
}

.all-selection-same-cls .ng-value {
  margin-top: 7px;
}

.message-table-click-opn {
  max-width: 530px;
  max-height: 100%;
  width: 530px;
  height: fit-content;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 13px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.correct-all-cls {
  margin: 0 auto;
  text-align: center;
  padding: 18px;
  background-color: #66d64f;
  border-radius: 13px 13px 0px 0px;
}

.correct-all-cls label {
  font-size: 22px;
  margin-top: 3px;
  font-weight: bold;
  color: #fff;
}

.message-table-click-opn-body label {
  color: #686868;
  font-weight: 700;
  font-size: 19px;
}

.message-table-click-opn-body {
  padding: 18px;
  margin: 0 auto;
  text-align: center;
}

.broken-btn button {
  background-color: #66d64f;
  border: 1px solid #66d64f;
  font-size: 18px;
  font-weight: 700;
  border-radius: 20px;
  padding: 10px 20px;
}

.broken-btn button:hover {
  background-color: #66d64f;
  border: 1px solid #66d64f;
}

.gray-box-message-cls .correct-all-cls {
  background: #b3b3b3 !important;
}

.gray-box-message-cls .correct-all-cls {
  background: #b3b3b3 !important;
}

.gray-box-message-cls button {
  background: #b3b3b3 !important;
  border: 1px solid #b3b3b3 !important;
}

.error-message-box-cls button {
  background: #ff0000 !important;
  border: 1px solid #ff0000 !important;
}

span.calendar-msg-ics-cls {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0px 0px;
}

.solution-ananlyst-logo img {
  max-width: 263px;
  margin: 0 auto;
  text-align: center;
}

/* Table related css over */

@media only screen and (max-width: 1250px) {
  .solution-ananlyst-logo {
    position: inherit !important;
  }

  /* .image-portion img {
    width: 100%;
    object-fit: cover !important;
    max-height: 675px;
  } */

  .logo-with-register-login-section {
    max-width: 100%;
  }

  .bg-color-change {
    height: 100%;
  }

  .sidebar-portion-start ul li a .link-text {
    font-size: 12px !important;
  }

  .sidebar-portion-start {
    padding: 18px 0px 18px 0px !important;
  }

  .sidebar-portion-start ul.list-unstyled li.gapping-cls-listing svg {
    width: 16px;
    height: 16px;
  }

  ul.list-unstyled li.gapping-cls-listing a {
    padding: 13px 5px 13px 8px;
  }

}

@media only screen and (max-width: 1440px) {
  .main-table-start-dashboard.my-3 canvas {
    width: 100% !important;
    height: 320px !important;
  }

  .solution-ananlyst-logo img {
    max-width: 173px;
  }

  .logo-with-register-login-section {
    max-width: 393px;
  }

  ul.list-unstyled li.gapping-cls-listing a {
    padding: 13px 8px 13px 8px !important;
  }

  .sidebar-portion-start ul li a .link-text {
    font-size: 12px;
  }

  .header-portion-start .left-side-logo img {
    height: 63px !important;
  }

  ul.list-unstyled li.gapping-cls-listing span svg {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  /* .image-portion img {
    max-height: 375px;
  } */


  .ul-li-responsive-cls {
    overflow-x: auto;
    width: 100% !important;
  }

  .main-table-start-dashboard.my-3 canvas {
    width: 100% !important;
    height: 160px !important;
  }

  .blocking-all-cls {
    display: block !important;
  }

  .blocking-all-cls .input-btn-cls {
    margin: 10px 0px 10px 0px;
  }
}

/* Common Media query css over */

/* 
.mdc-tab--active{
    background-color: #6c757d;
    color: white;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: white !important;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #3f3f44;
} */

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #d9e4e2 !important;
}
.no-record {
  display: flex;
  justify-content: center;
}
.mat-sidenav-content {
  width: 100% !important;
}
.mat-mdc-tab-body-content {
  /* display: flex; */
}
.modal-content {
  width: 1200px !important;
}

.details-portion-add {
  padding: 25px !important;
}
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  text-transform: capitalize;
}

.mat-tab--active {
  background-color: #9e9e9e;
}
.mat-mdc-card-header {
  display: flex;
  padding: 8px !important;
}
a {
  text-decoration: none !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #28aff8 !important;
  text-transform: capitalize;
  color: white !important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  /* padding: 12px;
    box-sizing: content-box; */
  margin-bottom: -11px !important ;
}

img:hover {
  cursor: pointer;
}
.margin-leftimage {
  margin-left: 30%;
}

.cursor {
  cursor: pointer;
}

.invoice-heading{
  transform: translate(7px, -71px);
}

th , td , tbody {
  text-align: center !important;
}

td>div , th>div {
  display: flex;
  justify-content: center;
}

.hover-box {
  color: #494d55;
  transition: background-color 0.3s ease;
}

.hover-box:hover {
  color: #28AFF8;
}

.custom-class .dropdown-list {
  position: absolute !important;
  padding-top: 14px;
  width: 100%;
  z-index: 99999;
  top: 30px !important;
  left: 0 !important;
}
.custom-class .arrow-down{
  display: none;
} 
.overlay-container{
  position: fixed;
  bottom: 30px;
  left: 0;
  z-index: 123;
}